<template>
    <div>
        <v-card>
            <v-container fluid class="ma-0 pa-0 q_leads_1">
                <v-row>
                    <v-col cols="3" sm="8" class="mb-0 pb-0">
                        <p class="q-display-3 pl-7 pb-0 pt-0 mt-0 mb-0 white--text" id="datatable">{{ title }}
                            <v-btn dark icon @click="refresh">
                                <v-icon>fas fa-redo-alt</v-icon>
                            </v-btn>
                        </p>
                        <!--<p class="pt-0 mt-0 pl-5">
                            <v-btn text small color="q_leads_4" @click="toggleFilters">
                                <v-icon small left>fas fa-filter</v-icon> Filter Leads
                            </v-btn>
                        </p>//-->
                    </v-col>
                    <v-col cols="9" sm="4" class="pr-7 mb-0">
                        <v-text-field dense dark v-model="search" :disabled="isDemoMode" append-icon="icon-q-search" label="Search" single-line hide-details v-on:keyup="maybeSearch" @click:append="refreshData"></v-text-field>
                    </v-col>
                </v-row>
            </v-container>
            <!--</v-card-title>-->
            <!--<v-row class="d-flex justify-start" v-if="toggleFilters" :id="entity + '_datatable'">
                <q-filters dark ref="filters" :hide-button="true" v-model="filters" :available-filters="available_filters" :keys="filter_keys"></q-filters>
            </v-row>//-->
            <v-data-table v-on:current-items="updateCurrentItems" :mobile-breakpoint="2" :items-per-page.sync="rows" :search="search" v-model="selected" :headers="the_headers" :items="all_data" item-key="$id" class="elevation-0 pointer" :footer-props="{itemsPerPageOptions:[10,25,50,100]}" :loading="downloading" :options.sync="options" :server-items-length="total_items">
                <template v-slot:item.CreateDate="{ item }">
                    <span class="nowrap">
                        <span>{{formatDateCalendar(item.CreateDate)}}</span>
                    </span>
                </template>
                <template v-slot:item.StartDate="{ item }">
                    <span class="nowrap">
                        <span>{{formatDateTime(item.StartDate)}}</span>
                    </span>
                </template>
                <template v-slot:item.EndDate="{ item }">
                    <span class="nowrap">
                        <span>{{formatDateTime(item.EndDate)}}</span>
                    </span>
                </template>
                <template v-slot:item.ID="{ item }">
                    <v-btn small v-if="item.Status == 'Done' && item.HasDataFile" @click="downloadExportFile(item)">Download</v-btn>
                    <v-btn small color="red" v-if="item.Status == 'Error' && item.HasErrorLog" @click="downloadErrorFile(item)">Errors</v-btn>
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>
<script>
import QDataTableMixin from "./QDataTableMixin"
import QuilityAPI from '@/store/API/QuilityAPI.js'
import { debounce } from 'vue-debounce'

export default {
    name: "QMyExportsDatatable",
    mixins: [QDataTableMixin],
    props: [],
    data: function() { // data internal to component (not available outside)
        return {
            entity: 'user',
            filters: {},
            firstPull: true, // this keeps the auto scrolling on data reloads from happening on the first load. 
            exports: [],
            options: { sortBy: ['CreateDate'], sortDesc: ["true"] },
            downloading: false,
            total_server_items: 0
        }
    },
    mounted: function() {

    },
    computed: {
        filter_keys: function() {
            return []
        },
        all_data: function() {
            return this.exports;
        },
        total_items: function() {
            return this.total_server_items;
        },
        the_headers: function() {

            var r = [{
                    text: 'Name',
                    value: 'JobName',
                    sortable: true,
                    filterable: true,
                },
                {
                    text: 'Status',
                    value: 'Status',
                    sortable: true,
                    filterable: true,
                },
                {
                    text: 'Download',
                    value: 'ID',
                    sortable: true,
                    align: 'center',
                },
                {
                    text: 'Create Date',
                    value: 'CreateDate',
                    align: 'start',
                    sortable: true,
                },
                {
                    text: 'Start',
                    value: 'StartDate',
                    align: 'start',
                    sortable: true,
                },
                {
                    text: 'End',
                    value: 'EndDate',
                    align: 'start',
                    sortable: true,
                }
            ]
            return r;
        },
    },
    methods: {
        loadData: debounce(function(opt) {
            var g = this
            g.downloading = true
            QuilityAPI.getJobs(this.filters, opt).then(function(results) {
                if (typeof results.data != 'undefined') {
                    g.exports = results.data
                    g.total_server_items = results.meta.total
                    g.downloading = false
                } else {
                    g.showError("Whoops! " + results.message);
                    g.downloading = false
                }
            }).catch(function(err) {
                console.log(err)
                if (err.status == 431) {
                    g.showError(err + " You probably need to clear all your cookie and session variables and login again.")
                    return
                }
                //g.showError(err.status)
            });
        }, 200),
        refresh: function() {
            this.exports = [];
            this.refreshData();
        },
        downloadExportFile: function(j) {
            this.downloading = true
            var g = this;
            QuilityAPI.downloadExportJob(j.ID)
                .then(function(resp) {
                    g.downloading = false
                    var fileURL = window.URL.createObjectURL(new Blob([resp]));
                    var fileLink = document.createElement('a');
                    fileLink.href = fileURL;
					const fileExtension = resp.type === 'application/zip' ? '.zip' : '.csv'
					fileLink.setAttribute('download', 'Export_' + j.JobName + '_' + j.ID + fileExtension)
                    fileLink.setAttribute('target', '_new');
                    document.body.appendChild(fileLink);
                    fileLink.click();
                })
                .catch(function(err) {
                    g.downloading = false
                    if (typeof err == 'undefined') {
                        return
                    }
                    if (err.status == 431) {
                        g.showError(err + " You probably need to clear all your cookie and session variables and login again.")
                        return
                    }
                    //g.showError(err.status)
                });
        },
        downloadErrorFile: function(j) {
            this.downloading = true
            var g = this;
            QuilityAPI.downloadBulkJobErrorLogFile(j.ID)
                .then(function(resp) {
                    g.downloading = false
                    var fileURL = window.URL.createObjectURL(new Blob([resp]));
                    var fileLink = document.createElement('a');
                    fileLink.href = fileURL;
                    fileLink.setAttribute('download', 'Error_' + j.JobName + '_' + j.ID + '.txt');
                    fileLink.setAttribute('target', '_new');
                    document.body.appendChild(fileLink);
                    fileLink.click();
                })
                .catch(function(err) {
                    g.downloading = false
                    if (typeof err == 'undefined') {
                        return
                    }
                    if (err.status == 431) {
                        g.showError(err + " You probably need to clear all your cookie and session variables and login again.")
                        return
                    }
                    //g.showError(err.status)
                });
        },
    },
    watch: {},
    components: {

    }
}
</script>
<style scoped>
.nowrap {
    white-space: nowrap;
}

.table-cursor tbody tr:hover {
    cursor: pointer;
}
</style>