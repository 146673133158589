<template>
    <div class="ma-5 symmetry_blue">
        <v-progress-linear indeterminate v-if="loading"></v-progress-linear>
        <div v-else-if="available_accounts.length > 0">
            <h3><span class="first-with-short-rule">Switch Accounts</span>
            </h3>
            <p>You have been given access to these accounts. Switching accounts will allow you to work in the portal with all the priviledges of the granting agent.</p>
            <v-select v-model="impersonate_agent_code" label="Account" :items="available_accounts" item-text="grantor_name" item-value="granting_agent_code"></v-select>
            <v-btn @click="impersonateAgent">Switch Account</v-btn>
        </div>
    </div>
</template>
<script>
import QuilityAPI from '@/store/API/QuilityAPI.js'
export default {
    name: "QImpersonateAccount",
    props: ['agent'], // data passed to component from parent
    data: function() { // data internal to component (not available outside)
        return {
            loading: false,
            available_accounts: [],
            impersonate_agent_code: null
        }
    },
    mounted: function() {
        this.getAccountGrants()
    },
    computed: {

    },
    methods: {
        getAccountGrants: function() {
            var g = this
            g.loading = true
            QuilityAPI.getAvailableAccountGrants().then(function(json) {
                g.loading = false
                if (typeof json.errors != 'undefined' && json.errors != "") {
                    g.showError(json.errors);
                    return
                }
                if (typeof json.msg != 'undefined') {
                    g.showError(json.msg);
                    return
                }
                g.available_accounts = json.data;
            }).catch(function(err) {
                g.loading = false
                if (typeof err.response != 'undefined' && err.response.clientError) {
                    g.showError(err.response.body.message);
                    return
                }
                if (typeof err.msg != 'undefined') {
                    g.showError(err.msg);
                    return
                }
                g.showError(err)
            });
        },
        impersonateAgent() {
            QuilityAPI.impersonateAgent(this.impersonate_agent_code).then(function(json) {
                if (json.success === true) {
                    document.location.href = process.env.VUE_APP_BASE_URL
                }
            })
        },
    },
    watch: {

    },
    components: {}
}

</script>
<style scoped>
</style>
