<template>
    <v-container fluid class="grey lighten-5 my-0 px-0  py-0">
        <v-row class="mx-7">
            <v-col cols="12">
                <q-my-exports-datatable title="My Exports"></q-my-exports-datatable>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import QMyExportsDatatable from '@/components/datatables/QMyExportsDatatable.vue';


export default {
    data() {
        return {}
    },
    mounted: function() {},
    computed: {},

    methods: {

    },

    watch: {

    },
    components: {
        QMyExportsDatatable
    }
}

</script>
