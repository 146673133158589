<template>
    <div class="symmetry_blue">
        <h3><span class="first-with-short-rule">Account Grants</span></h3>
        <div class="d-inline-flex justify-start mb-6">
            <v-btn outlined color="primary" left @click="startNewGrant">Add New <v-icon right>fas fa-plus</v-icon>
            </v-btn>
            <p class="body-2 mx-5" style="max-width:800px;">When creating new grants, if the user's email does not already exists in the system, a new account will be created. If the email does exist, the grant will be applied to the existing user's account.</p>
        </div>
        <p>The following users have access to your account and can view all data associated with your account. They can also do work on your behalf, like add support tickets or create admin notices.</p>
        <q-account-grants-data-table class="mx-n4" title="Account Grants" :agent="agent" ref="grant_table"></q-account-grants-data-table>
        <v-dialog v-model="showNewGrantDialog" width="450px" max-width="90%">
            <v-card class="pa-4">
                <v-card-title>
                    <h4 class="q-display-1 pa-5 white--text text-center q_leads_1" style="min-width:100%;">New Grant</h4>
                </v-card-title>
                <v-card-text>
                    <v-text-field v-model="account_grant.name" label="Full Name"></v-text-field>
                    <v-text-field v-model="account_grant.email" label="Email"></v-text-field>
                </v-card-text>
                <v-card-actions class="text-center">
                    <v-spacer></v-spacer>
                    <v-progress-circular indeterminate v-if="savingGrant"></v-progress-circular>
                    <v-btn v-else color="primary" large @click="createAccountGrant">Add</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import QuilityAPI from '@/store/API/QuilityAPI.js'
import QAccountGrantsDataTable from '@/components/datatables/QAccountGrantsDataTable.vue'
export default {
    name: "QAccountGrants",
    props: ['agent'], // data passed to component from parent
    data: function() { // data internal to component (not available outside)
        return {
            savingGrant: false,
            showNewGrantDialog: false,
            account_grant: {
                name: null,
                email: null,
            }
        }
    },
    mounted: function() {

    },
    computed: {

    },
    methods: {
        createAccountGrant: function() {
            const g = this
            g.savingGrant = true
            QuilityAPI.createAccountGrant(g.account_grant.email, g.account_grant.name).then(function(json) {
                g.savingGrant = false
                if (typeof json.errors != 'undefined' && json.errors != "") {
                    g.showError(json.errors);
                    return
                }
                if (typeof json.msg != 'undefined') {
                    g.showError(json.msg);
                    return
                }
                g.showNewGrantDialog = false
                g.$refs.grant_table.refreshData()
            }).catch(function(err) {
                g.savingGrant = false
                if (typeof err.response != 'undefined' && err.response.clientError) {
                    g.showError(err.response.body.message);
                    return
                }
                if (typeof err.msg != 'undefined') {
                    g.showError(err.msg);
                    return
                }
                g.showError(err)
            });
        },
        startNewGrant: function() {
            this.account_grant = {
                name: null,
                email: null,
            }
            this.showNewGrantDialog = true;
        }
    },
    watch: {

    },
    components: {
        QAccountGrantsDataTable
    }
}

</script>
<style scoped>
</style>
