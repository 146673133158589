<template>
    <div>
        <v-card flat>
            <!--</v-card-title>-->
            <!--
            <v-row class="d-flex justify-start" v-if="toggleFilters">
                <q-filters dark ref="filters" :hide-button="true" v-model="filters" :available-filters="available_filters" :keys="filter_keys"></q-filters>
            </v-row>
            //-->
            <v-data-table v-on:current-items="updateCurrentItems" :mobile-breakpoint="2" :items-per-page.sync="rows" :search="search" v-model="selected" :headers="the_headers" :items="all_data" item-key="id" class="elevation-0" :footer-props="{itemsPerPageOptions:[10,25,50,100]}" :loading="loading" :options.sync="options" :server-items-length="total_items">
                <!--adds view ticket icon -->
                <template v-slot:item.id="{ item }">
                    <span v-if="item.avatar" class="v-avatar">
                        <v-img :src="item.avatar" width="50" height="50"></v-img>
                    </span>
                </template>
                <template v-slot:item.enabled="{ item }">
                    <span class="">
                        <v-switch v-model="item.enabled" v-on:change="toggleEnabled(item)"></v-switch>
                    </span>
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>
<script>
import QGenericDataTableMixin from "./QGenericDataTableMixin"
import QuilityAPI from '@/store/API/QuilityAPI.js'
import { debounce } from 'vue-debounce'

export default {
    name: "QAccountGrantsDataTable",
    props: ['agent'],
    mixins: [QGenericDataTableMixin],
    data: function() { // data internal to component (not available outside)
        return {
            //use this to "preset" a filter
            filters: {},
            options: {
                'sortBy': [],
                'sortDesc': [true]
            },
            expanded: [],
        }
    },
    computed: {
        loading: function() {
            return this.data_loading;
        },
        all_data: function() {
            return this.the_data;
        },
        filter_keys: function() {
            return [];
        },
        total_items: function() {
            return this.total_num;
        },
        the_headers: function() {
            return [{
                    text: 'Avatar',
                    value: 'id',
                    class: "nowrap",
                    sortable: false,
                }, {
                    text: 'Name',
                    value: 'name',
                    class: "nowrap",
                    sortable: false,
                    align: "left"
                }, {
                    text: 'Email',
                    value: 'email',
                    sortable: false,
                    align: "left"
                }, {
                    text: 'Enabled',
                    value: 'enabled',
                    sortable: false,
                },

            ]
        },
    },
    methods: {
        loadData: debounce(function(opt) {
            var g = this
            this.data_loading = true
            QuilityAPI.getAccountGrants(this.agent).then(function(json) {
                g.data_loading = false
                if (typeof json.response != 'undefined' && json.response.clientError) {
                    g.showError(json.response.body.message);
                    return
                }
                g.$set(g, 'the_data', json.data);
                g.total_num = json.total
            }).catch(function(err) {
                g.data_loading = false
                if (err.status == 431) {
                    g.showError(err + " You probably need to clear all your cookie and session variables and login again.")
                    return
                }
                g.showError(err.message);
            });
            this.firstPull = false;
        }, 200),
        toggleEnabled: function(item) {
            var g = this
            QuilityAPI.updateAccountGrant(item.id, item.email, item.name, item.enabled).then(function(json) {
                if (typeof json.response != 'undefined' && json.response.clientError) {
                    g.showError(json.response.body.message);
                    return
                }
                if (json.isDemoMode) {
                    g.showError(json.msg);
                    return
                }
                console.log(json)
                g.data_loading = false
                var x = indexByKey(id, g.the_data, 'id')
                g.the_data[ix] = json.data
            }).catch(function(err) {
                g.data_loading = false
                if (err.status == 431) {
                    g.showError(err + " You probably need to clear all your cookie and session variables and login again.")
                    return
                }
            });
        },
    },
    watch: {

    },
    components: {}
}

</script>
<style scoped>
.nowrap {
    white-space: nowrap;
}

</style>
