<template>
    <v-container v-if="isUserSFGDirect" fluid class="grey lighten-5 my-0 px-0 py-0 h-full" style="height: 100%;">
        <div class="mx-5 mt-5" style="height: 100%;">
            <v-card style="height: 100%;" :disabled="saving">
                <v-card-title>
                    <h3>PCS Program</h3>
                </v-card-title>
                <v-card-text>
                    <div style="max-width:1400px; margin:0 auto;">
                        <v-progress-linear indeterminate v-if="saving"></v-progress-linear>
                        <v-row class="d-flex" wrap dense>
                            <v-col cols="12" wrap>
                                <v-radio-group v-model="pcsOwner" dense label="Do you wish to hold control of the PCS program for your hierarchy?">
                                    <v-radio label="Yes - I will assume ownership and responsibility for PCS in my hierarchy." :value="true"></v-radio>
                                    <v-radio label="No - I want to roll up ownership of my hierarchy’s PCS to my upline manager." :value="false"></v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>

                        <v-row v-if="pcsOwner" class="d-flex" wrap dense>
                            <v-col cols="12" wrap>
                                <v-radio-group v-model="pcsModel" dense label="Which of the 4 operating models would you like for PCS in your hierarchy?">
                                    <v-radio label="Model AA: Agent Level Lead Distribution, Original Agent Commission split" value="AA"></v-radio>
                                    <v-radio label="Model AD: Agent Level Lead Distribution, PCS Owner commission split" value="AD"></v-radio>
                                    <v-radio label="Model DA: PCS Owner Level Lead distribution, Original Agent Commission split" value="DA"></v-radio>
                                    <v-radio label="Model DD: PCS Owner Level Lead distribution, PCS Owner commission split" value="DD"></v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>

                        <v-row class="d-flex" wrap dense>
                            <v-col cols="12" wrap>
                                <v-btn color="primary" depressed @click="saveAgentConfigs()" :disabled="isButtonDisabled">
                                    Save
                                </v-btn>
                            </v-col>
                        </v-row>
                    </div>
                </v-card-text>
            </v-card>
        </div>
    </v-container>
</template>

<script>
import QuilityAPI from '@/store/API/QuilityAPI'

export default {
    name: "PCSProgram",
    data() {
        return {
            saving: false,
            pcsOwner: undefined,
            pcsModel: '',
        }
    },
    created() {
        if (!this.isUserSFGDirect) {
            console.log(this.user)
            this.$router.push('/404');
        }

        this.pcsOwner = this.PCSOwnerConfigValue || undefined
        this.pcsModel = this.PCSModelConfigValue || ''
    },
    computed: {
        isButtonDisabled() {
            return this.pcsOwner === undefined || this.pcsOwner && !this.pcsModel || this.saving
        },
        PCSOwnerConfigValue() {
            return this.user.config.PCSOwner?.ConfigValue
        },
        PCSModelConfigValue() {
            return this.user.config.PCSModel?.ConfigValue
        },
        isUserSFGDirect() {
            return this.user.IsSFGDirect
        },
    },
    methods: {
        async saveAgentConfigs() {
            this.saving = true
            await Promise.all([
                this.saveAgentConfig('PCSOwner', this.pcsOwner, 'boolean'),
                this.saveAgentConfig('PCSModel', this.pcsModel, 'string'),
            ])
            this.saving = false
        },
        saveAgentConfig(key, value, type) {
            return QuilityAPI.updateConfig(key, value, type)
                .then((res) => {
                    if (res.error || !res.success) {
                        this.showError('Oops! There was a problem saving user config.', res.cause)
                        return
                    }
                })
        },
    },
    watch: {
        pcsOwner(newValue) {
            if (!newValue) {
                this.pcsModel = ''
            }
        },
    },
}
</script>
